
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormInputText from "@/components/form/InputText.vue";
import { useFilter, formatCPF, removeCPFMask } from "@/libs/utils";

const VPatientFilter = defineComponent({
  name: "VPatientFilter",
  components: { CFormInputText },
  setup() {
    useHead({ title: "Filtrar pacientes | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilter();

    const filters = reactive({
      nm_pessoa_fisica: route.query.nm_pessoa_fisica || undefined,
      nr_cpf: route.query.nr_cpf || undefined,
    });
    const loading = reactive({ submit: false });

    async function applyFilters() {
      router.replace({ query: { ...route.query, ...filters } });

      loading.submit = true;
      await store.dispatch("searchPatients", { search: filters, isPrimary: true });
      loading.submit = false;
    }

    function resetFilters() {
      filters.nm_pessoa_fisica = undefined;
      filters.nr_cpf = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "patient-list", query: route.query });
    }

    if (hasAnyFilters.value) applyFilters();

    return {
      ...{ filters, loading },
      ...{ applyFilters, resetFilters, handleClose },
      ...{ formatCPF, removeCPFMask },
    };
  },
});

export default VPatientFilter;
